export const terminalMixin = {
    data(){
        return{
             // 设置终端链接
             terminalStatus:false,

             terminalNum:0,
            //  分页orgCode
             termianlOrgcode:'',
            //  分页orgId
             termianOrgId:''
        }
    },
    methods:{
        // 方法部分
        // 取消终端链接模态框方法
        terminalInput(status){
            this.terminalStatus = status
        },
        // 终端链接确定安妮
        saveTerminal(){
            if(this.$refs.terminal.tableData.length == 0){
                this.$Message.warning({
                    content:'设置的终端链接不能为空',
                    background:true
                })
                return
            }
            this.$refs.terminal.addModul()
        },
        setSuccess(){
            this.terminalStatus = false
        },
      
        
    }
}