<template>
  <div class="container-div">
    <div class="btn-div">
        <Button type="primary" @click="openAdd">新增</Button> 
    </div>
    <div class="have-container" v-if="tableData && tableData.length != 0 && showTable == true">
        <div class="item" v-for="(item,index) in tableData" :key="index">
            <Tag type="dot" closable color="primary" @on-close="(val) => closeTable(val,index)">{{item.moduleName}} - {{item.systemName}}</Tag>   
        </div>
    </div>
    <div class="container" v-if="(!tableData || tableData.length == 0) && showTable == true ">
        <div class="none">暂无数据</div>
    </div>
    

    <LiefengModal :fullscreen="false" :width="400" :height="200" title="新增终端链接" @input="terminalInput" :value="terminalStatus" >
       <template #contentarea>
           <div class="form-div">
               <Form
                :label-colon="true"
                :label-width="100">
                <FormItem>
                     <span slot="label" class="validate">选择系统:</span>
                    <Cascader @on-change="changeSystem" change-on-select v-model="modules.systemId" style="width:200px;margin-right:10px;display:inline-block;text-align:left" :data="cascaderList" :load-data="loadData"></Cascader>
                </FormItem>
                <FormItem>
                     <span slot="label" class="validate">选择模块:</span>
                     <Tag class="tag-div" v-if="handleData && handleData.name" type="dot" closable color="primary" @on-close="handleClose">{{handleData.name || '未知'}}</Tag> 
                     <Button v-else  type="primary" @click="changeModule">点击选择</Button>
                </FormItem>
               </Form>
           </div>
       </template>
       <template #toolsbar>
            <Button type="info" style="margin-right:10px" @click="termainalTip=true;terminalInput(false)">取消</Button>
            <Button type="primary" @click="saveTerminal">确定</Button>
       </template>
     </LiefengModal>
    <LiefengModal :fullscreen="false" :width="400" :height="200" title="新增终端链接" @input="treeInput" :value="treeStatus" class="termainl-modal">
       <template #contentarea>
           <Tree v-if="showTree" @handleTree="handleTree"></Tree>
       </template>
       <template #toolsbar>
            <Button type="primary" @click="saveTree">确定</Button>
       </template>
     </LiefengModal>

  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import Tree from './treeview.vue'
export default {
    components:{LiefengModal,Tree},
    props:['terminalNum','termianlOrgcode','termianOrgId'],
    data(){
        return{
            terminalStatus:false,
            modules:{
                systemId:[]
            },
            cascaderList:[],
            treeStatus:false,
            showTree:false,
            showTable:false,
            handleData:{},
            systemData:{},

            termainalTip:false
        }
    },
    methods:{
        // 方法部分
        // 删除tag事件
        closeTable(item,index){
            this.showTable = false
            this.$nextTick(()=>{
                this.tableData.splice(index,1)
                this.showTable = true
            })
        },
        // 选择系统事件
        changeSystem(val,item){
            if(item && item.length != 0){
                this.systemData = item[item.length -1]
            }else{
                this.systemData = {}
            }
        },
        handleClose(){
            this.handleData = {}
        },
        handleTree(val){
            this.handleData = val
        },
        treeInput(status){
            // this.treeStatus = status
            // this.showTree = false
        },
        saveTree(){
            this.treeStatus = false
            this.showTree = false
        },
        // 点击新增按钮
        openAdd(){
            this.terminalStatus = true
            this.handleData = {}
            this.modules.systemId = []
            this.getCasacader()
        },
        // 关闭弹窗方法
        terminalInput(status){
            if(!status && this.termainalTip){
                this.terminalStatus = status
                this.termainalTip = false
            }else{
                this.terminalStatus = status
            }
            
        },
        // 弹窗确定按钮
        saveTerminal(){
            let isTrue = false
            if(!this.systemData.value || this.systemData.value == ''){
                this.$Message.warning({
                    content:'请选择系统ID',
                    background:true
                })
                return
            }
            if(!this.handleData.name || this.handleData.name == ''){
                this.$Message.warning({
                    content:'请选择系统模块',
                    background:true
                })
                return
            }
            if(this.tableData.length != 0){
                this.tableData.map(item =>{
                    console.log('tabledata',item);
                    if(item.systemId == this.systemData.value){
                        console.log('符合条件了',);
                        isTrue = true
                    }
                })
            }
            if(isTrue == true){
                this.$Message.warning({
                    content:`已选择系统${this.systemData.label},请勿重复选择`,
                    background:true
                })
                return
            }
            let data = {
                appCode:this.systemData.appCode,
                clientType:this.systemData.clientType,
                moduleId:this.handleData.sysModuleId,
                moduleName:this.handleData.name,
                systemId:this.systemData.value,
                systemName:this.systemData.label
            }
            this.tableData.push(data)
            this.terminalStatus = false
            console.log(this.tableData);
        },
        // 点击选择模块按钮
        changeModule(){
            this.treeStatus = true
            this.showTree = true
        },
        // 接口部分
         // callback级联函数
          loadData (item, callback) {
               item.loading = true
               this.$get('/gx/pc/organization/orgSystem/selectSystemList',{
                    keyword:'',
                    parentId:item.value
                }).then(res=>{
                    if(res.code == 200){
                        let arr = res.dataList.map(item=>{
                            return {
                                value:item.systemId,
                                label:item.appName,
                                parentId:item.parentId,
                                appCode:item.appCode,
                                clientType:item.clientType
                            }
                        })
                        item.loading = false
                        item.children = arr
                        callback(item.children)
                    }else{
                        callback([])
                    }
                })
                
         },
         //获取级联
        getCasacader(){
            this.$get('/gx/pc/organization/orgSystem/selectSystemList',{
                keyword:'',
                parentId:0
            }).then(res=>{
                if(res.code == 200){
                    this.cascaderList = res.dataList.map(item=>{
                        if(item.hasChild == '2'){
                            return {
                                value:item.systemId,
                                label:item.appName,
                                children:[],
                                loading: false,
                                parentId:item.parentId,
                                appCode:item.appCode,
                                clientType:item.clientType
                            }
                        }else{
                            return {
                                value:item.systemId,
                                label:item.appName,
                                parentId:item.parentId,
                                appCode:item.appCode,
                                clientType:item.clientType
                            }
                        }
                    })
                }else{
                    this.$Message.error({
                        content:'获取级联选择失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 获取机构所拥有的终端
        getTerminalPage(orgCode){
            this.$get('/gx/pc/organization/orgSystem/selectByOrgHasSystemModule',{
                orgCode
            }).then(res=>{
                if(res.code == 200){
                    this.tableData = res.dataList
                    this.showTable = false
                    this.$nextTick(()=>{
                        this.showTable = true
                    })
                }else{
                    this.$Message.error({
                        content:'获取终端链接列表失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 新增模块按钮
        addModul(){
            this.$post('/gx/pc/organization/orgSystem/authorizationModule',{
                modules:this.tableData,
                orgCode:this.termianlOrgcode,
                orgId:this.termianOrgId
            },{'Content-Type':'Application/json'}).then(res =>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'设置终端链接成功',
                        background:true
                    })
                    this.$emit('setSuccess')
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        }
    },
    watch:{
        terminalNum(){
            this.tableData = []
            this.getTerminalPage(this.termianlOrgcode)
        }
    }
}
</script>

<style scoped lang="less">
.container-div{
    .container{
        height: 300px;
        text-align: center;
        overflow-y: scroll;
        .none{
            color:#666666;
            height: 300px;
            line-height: 300px;
        }
    }  
    .have-container{
        height: 300px;
        overflow-y: scroll;
        .item{
            margin: 10px 0;
        }
    }
}
 /deep/ #modal_contentarea{
        overflow: visible !important;
    }
    .termainl-modal{
            /deep/.ivu-icon-ios-close {
                display: none;
            }     
    }
  /deep/.validate {
        &::before {
            content: "*";
            display: inline-block;
            margin-right: 4px;
            line-height: 1;
            font-family: SimSun;
            font-size: 14px;
            color: #ed4014;
        }
    }
</style>