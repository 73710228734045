import { render, staticRenderFns } from "./addsub.vue?vue&type=template&id=4efd331a&scoped=true&"
import script from "./addsub.vue?vue&type=script&lang=js&"
export * from "./addsub.vue?vue&type=script&lang=js&"
import style0 from "./addsub.vue?vue&type=style&index=0&id=4efd331a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4efd331a",
  null
  
)

export default component.exports