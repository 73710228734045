<template>
  <div class="department-div">
     <div class="department-button">
         <Button style="margin-left:auto;" type="primary" @click="clickAdd">新增</Button>
     </div>
     <div class="department-table">
       <Table  :load-data="handleLoadData" :columns="talbeColumns" :data="tableData" row-key="orgId" height="700" border stripe></Table>
         <Page
                style="float: right; margin: 10px"
                @on-change="morePageFn"
                :current="currentPage"
                @on-page-size-change="PageSizeChange"
                :page-size="pageSize"
                :total="total"
                :page-size-opts="[20, 50, 100, 200]"
                size="small"
                show-total
                show-elevator
                show-sizer
              />
     </div>
     <LiefengModal :fullscreen="false" :title="isAdd?'新增部门':'修改部门'" @input="openAdd" :value="openAddStatus">
        <template #contentarea>
            <AddForm :orgId='dataOrgId' :formData="formData" :isShow="isShow" @changeCascader="changeCascader"></AddForm> 
       </template>
       <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="openAddTip=true;openAdd(false)">取消</Button>
            <Button type="primary" @click="saveAdd">保存</Button>
       </template>
     </LiefengModal>
  </div>
</template>

<script>
  import LiefengModal from "@/components/LiefengModal";
  import AddForm from './addForm'
  export default {
    props:{
        dataOrgId:{
            type:String,
            default:()=>{
                return ''
            }
        }
    },
    //import引入的组件需要注入到对象中才能使用
    components: {LiefengModal,AddForm},
    data() {
      //这里存放数据
      return {
          talbeColumns:[
              {
                title:'部门名称',
                key:'orgName',
                minWidth:200,
                align:'center',
                tree: true,
              },
              {
                title:'部门编码',
                key:'orgCode',
                minWidth:200,
                align:'center',
              
              },
              {
                title:'上级菜单',
                key:'parentName',
                minWidth:200,
                align:'center'
              },
              {
                title:'排序',
                key:'seq',
                minWidth:200,
                align:'center'
              },
              {
          title: '操作',
          key: 'action',
          // fixed: 'right',
          align: "center",
          minWidth:300,
          render: (h, params) => {
            return h('div', [
              h('Button',{
                props:{
                  type: "info",
                  size: "small",
                },
                style: {
                    marginRight: "10px",
                  },
                on:{
                  click:()=>{ 
                    ++this.isShow
                    this.isAdd = false
                    this.formData = {}
                    this.formData = {
                      orgName:params.row.orgName,
                      seq:params.row.seq,
                      treeParentId:params.row.parentId,
                      orgId:params.row.orgId
                    }
                    this.openAddStatus = true              
                  }
                }
              },'修改'),
      
             
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small',
                },
                style: {},
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: "温馨提示",
                      content: "<p>您正在删除当前数据，此操作不可逆，是否继续？</p>",
                      onOk: () => {
                       this.deleteFn(params.row.orgId)
                      },
                    })
                    
                  }
                }
              }, '删除'),
            ]);
          }
        }
          ],
          tableData:[],
          loading:false,
          page:1,
          pageSize:20,
          total:0,
          currentPage:0,

        //  新增模态框的数据
        openAddStatus:false,
        openAddTip:false,
        treeOrgId:'',
        formData:{
          orgId:'',
          orgName:'',
          seq:0,
          treeParentId:''
        },
        isShow:0,
        isAdd:true,
        selectParent:[]
      }
    },
    //方法集合
    methods: {
      morePageFn(val) {
         this.currentPage = val;
         this.page = val
         this.getList()
     },
      PageSizeChange(val){
        this.pageSize = val
        this.page = 1
        this.getList()
      },
      handleLoadData(item,callback){
        item._loading = true
        item.children = []
        this.$get('/gx/pc/dept/selectDeptChild',{
          orgId:item.orgId
        }).then(res=>{
            let arr = []
            // item._loading = false
            if(res.code == 200 && res.dataList.length != 0 ){
              res.dataList.map(items=>{
                items._loading = false
                items.children = []
              })
              arr = res.dataList
              callback(arr)
            }else{
              item._loading = false
              delete item.children
              delete item._loading
            }
        })
      },
        //点击了cascader的回调事件
        changeCascader(val){
          // this.dataOrgId = val
          this.selectParent = val
        },
        // 打开新增模态框
        openAdd(status){
            if(!status && this.openAddTip){
                this.openAddStatus = status
                this.openAddTip = false
                this.treeOrgId = ''
                this.isAdd = true
                this.selectParent = []
            }else{
                this.openAddStatus = status
            }
        },
        // 新增保存按钮
        saveAdd(){
           if(this.formData.orgName == '') {this.$Message.warning({content:'部门名称不能为空',background:true});return}
           if(this.isAdd){
             let id = ''
             if(this.selectParent.length != 0){
               id = this.selectParent[this.selectParent.length -1]
               console.log(1,id);
             }else{
               id = this.dataOrgId
               console.log(2,id);
             }
            console.log('拿到的父节点id',id)
            this.saveDept(id)
           } 
           else{
             let id = ''
             if(this.selectParent.length != 0){
               id = this.selectParent[this.selectParent.length - 1]
             }else{
               if(this.formData.treeParentId != ''){
                  id = this.formData.treeParentId
               }
             }
             let data = {
               orgId:this.formData.orgId,
               orgName:this.formData.orgName,
               parentId:id,
               seq:this.formData.seq
             }
             if(data.orgId == id){
               this.$Message.error({
                 content:'上级部门与当前部门不能一致',
                 background:true
               })
               return
             }
             this.updateFn(data)
           }
        },
        // 点击新增部门按钮
        clickAdd(){
            this.openAddStatus = true
            this.treeOrgId = this.dataOrgId
            ++this.isShow
            this.formData.orgName = ''
            this.formData.seq = 0
            this.formData.treeParentId = ''
            this.isAdd = true
            this.selectParent = []
        },
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        getList(){
            this.loading = true
            this.$get('/gx/pc/dept/selectDeptPage',{
                dataOrgId:this.dataOrgId,
                page:this.page,
                pageSize:this.pageSize
            }).then(res=>{
                if(res.code == 200){
                    this.loading = false
                    if(res.dataList.length != 0){
                      res.dataList.map(item=>{
                        item._loading = false
                        // item.children = [{}]
                      })
                    }
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                }else{
                     this.loading=false
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        // 保存和修改部门接口
        saveDept(data){
          this.$post('/gx/pc/dept/saveDept',{
            orgName:this.formData.orgName,
            parentId:data,
            seq:this.formData.seq
          }).then(res=>{
            if(res.code == 200){
              this.$Message.success({
                content:'操作成功',
                background:true
              })
               this.openAddStatus = false
               this.page = this.currentPage
               this.formData.orgName = ''
               this.formData.seq = 0
               this.formData.treeParentId = ''
               this.isAdd = true
               this.getList()
            }else{
              this.$Message.error({
                content:res.desc,
                background:true
              })
              return
            }
          })
        },
        deleteFn(orgId){
          this.$post('/gx/pc/dept/deleteById',{
            orgId
          }).then(res=>{
            if(res.code == 200){
              this.$Message.success({
                content:'删除成功',
                background:true
              })
              this.page = this.currentPage
              this.getList()
            }else{
              this.$Message.error({
                content:res.desc,
                background:true
              })
              return
            }
          })
        },
        // 更新接口
        updateFn(data){
           this.$post('/gx/pc/dept/saveDept',{
            orgId:data.orgId,
            orgName:data.orgName,
            parentId:data.parentId,
            seq:data.seq
          }).then(res=>{
            if(res.code == 200){
              this.$Message.success({
                content:'操作成功',
                background:true
              })
              this.openAddStatus = false
              this.openAddTip = false
              this.isAdd = true
              this.page = this.currentPage
              this.getList()
            }else{
              this.$Message.error({
                content:res.desc,
                background:true
              })
              return
            }
          })
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        
    },
    watch:{
        dataOrgId:{
            handler(val,newVal){
                if(val){
                    this.currentPage = 1
                    this.page = 1
                    this.pageSize = 20
                    this.total = 0
                    this.getList()    
                                 
                }
            }
        }
    }
  }
</script>
<style lang='less' scoped>
.department-div{
    .department-button{
        margin-bottom: 10px;
        display: flex;
    }
    //编写css
  /deep/.ivu-table-wrapper{
      height: 675px !important;
  }
}
 /deep/ #modal_contentarea{
        overflow: visible !important;
}
/deep/ .ivu-table-cell{
  display: flex !important;
  justify-content: left;
  .ivu-icon{
    font-size: 20px;
    color: white;
    background: rgb(128, 128, 128);
    border-radius: 50%;
    margin-right: 5px;
    font-weight: bold;
  }
}
/deep/.ivu-table-body{
  padding-bottom: 20px;
}
</style>