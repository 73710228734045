<template>
  <div class="sub-div">
      <div class="top">
         <!-- <Input style="width:300px" v-model="searchName" search enter-button="查询" placeholder="请输入关联机构名称" @on-search="search" /> -->
         <Button type="primary" @click="openAdd">新增关联</Button>
         <!-- <Button type="error" style="margin-left:10px" @click="deleteAll">批量删除</Button> -->
      </div>
      <div class="bottom">
           <Table  :loading="tableLoading" :columns="talbeColumns" :data="tableData"  height="660" border stripe></Table>
      </div>
      <LiefengModal :fullscreen="true" title="新增关联" @input="openSubordinate" :value="subordinateStatus">
       <template #contentarea>
          <Addsub :showNumber="showNumber" :rightList="rightList" @deleteAll="deleteAll" @handleSelect="selectList"></Addsub>
       </template>
       <template #toolsbar>
            <Button type="info" style="margin-right:10px" @click="openSubordinate(false)">取消</Button>
            <Button type="primary" @click="saveSub">确定</Button>
       </template>
     </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import Addsub from './addsub.vue'
export default {
    props:['isShow','orgCode'],
    components:{LiefengModal,Addsub},
    data(){
        return{
            searchName:'',
            talbeColumns:[
                {
                    title:'关联机构名称',
                    key:'orgName',
                    align:'center',
                    minWidth:200
                },
                {
                    title:'详细地址',
                    key:'wholeAddr',
                    align:'center',
                    minWidth:200
                },
                {
                    title:'机构编码',
                    key:'orgCode',
                    align:'center',
                    minWidth:200
                },
                {
                    title: '操作',
                    align: "center",
                    width: 200,
                    render: (h, params) => {
                        return h('div', [
                            h('Button', {
                                props: {
                                type: 'error',
                                size: 'small',
                                },
                                style: {},
                                on: {
                                    click: () => {
                                        this.$Modal.confirm({
                                             title: "温馨提示",
                                            content:
                                            "<p>您正在删除当前数据，是否继续？</p>",
                                            onOk: () => {
                                                this.deleteRow(params.row.orgCode)
                                            }
                                        })
                                    }
                                }
                            }, '删除'),
                        ]);
                    }
                }
            ],
            tableData:[],
            page:1,
            pageSize:20,
            total:0,
            tableLoading:false,
            subordinateStatus:false,

            // 新增下级关联
            showNumber:0,
            rightList:[]

        }
    },
    methods:{
       //方法部分
    // 分页选择框事件
    selectList(val){
        this.rightList = val
    },
    //  点击了清空按钮
    deleteAll(){
        this.rightList = []
    },
       saveSub(){
           if(this.rightList.length == 0){
               this.$Message.warning({
                   content:'请选择想要关联的组织机构',
                   background:true
               })
               return
           }
           let data = []
           this.rightList.map(item=>{
               data.push(item.orgCode)
           })
           this.authorization(data)
       },
       openSubordinate(status){
           this.subordinateStatus = status
       },
       //新增关联
       openAdd(){
           this.subordinateStatus = true
           ++this.showNumber
       },
       //接口部分
       getList(){
           this.tableLoading = true
           this.$get('/gx/pc/orgScope/selectOrgManageScope',{
               orgCode:this.orgCode
           }).then(res=>{
               this.tableLoading = false
               if(res.code == 200){
                this.tableData = []
                this.tableData = res.dataList
                this.rightList = this.tableData
               }else{
                   this.$Message.error({
                       content:'获取关联机构数据失败',
                       background:true
                   })
                   return
               }
           })
       },
       deleteRow(orgCodes){
           this.$post('/gx/pc/orgScope/deleteAuthorization',{
               orgCode:this.orgCode,
               orgCodes:orgCodes
           }).then(res=>{
               if(res.code == 200){
                   this.$Message.success({
                       content:'删除成功',
                       background:true
                   })
                   this.getList()
               }else{
                   this.$Message.error({
                       content:'删除失败',
                       background:true
                   })
                   return
               }
           })
       },
       authorization(data){
           this.$post('/gx/pc/orgScope/authorization',{
               orgCode:this.orgCode,
               orgCodeList:data
           },{'Content-Type':"application/json"}).then(res=>{
               if(res.code == 200){
                   this.$Message.success({
                       content:'保存成功',
                       background:true
                   })
                    this.subordinateStatus = false
                    this.getList()
               }else{
                   this.$Message.error({
                       content:res.desc,
                       background:true
                   })
                   return
               }
           })
       }
    },
    watch:{
        isShow(val){
            
            this.getList()
        }
    }
}
</script>

<style lang="less" scoped>
.sub-div{
    .top{
        margin: 10px 0;
        display:flex;
    }
    .bottom{

    }
}
</style>