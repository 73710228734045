<template>
  <div>
      <Tree ref='tree' :data="treeData" show-checkbox class="tree-div" :multiple="false"></Tree>
  </div>
</template>

<script>

  export default {
    props:{
        custGlobalId:{
            type:String,
            default:() => {
                return ''
            }
        },

    },
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
      //这里存放数据
      return {
          treeData:[],
      }
    },
    //方法集合
    methods: {
        getTree(data){
            if(data && data != ''){
                 this.$get('/old/api/pc/menu/getPlatFormMenuTree',{
                     custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                     orgCode:data
                 }).then(res=>{
                     if(res.code == 200){
                         this.treeData = res.dataList
                     }else{
                         this.$Message.error({
                             content:res.desc,
                             background:true
                         })
                     }
                 })
            }
           
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        
    },
    watch:{
        custGlobalId:{
            handler(val,newVal){
                if(val){
                    console.log('val改变了',val);
                    
                    this.getTree(val)
                }
            }
        }
    }
  }
</script>
<style lang='less' scoped>
  //编写css
  .tree-div{
      height: 100%;
      border: 1px solid #eeeeee;
  }
</style>