<template>
  <div class="tree">
      <Tree :data="treeData" @on-select-change="handleTree" :load-data="loadData"></Tree>
  </div>
</template>

<script>
export default {
    data(){
        return{
            treeData:[]
        }
    },
    methods:{
        // 方法部分
        handleTree(val){
            this.$emit('handleTree',val[0])
        },
        loadData(item,callback){
            item.loading = true
            this.$get('/auth/api/auth/pc/systemModule/cascade',{
                    parentId:item.sysModuleId,
                    systemId:'13' || this.$route.query.systemId
                }).then(res=>{
                    if(res.code == 200){
                        if(res.dataList && res.dataList.length != 0){
                          let arr =  res.dataList.map(item =>{
                              if(item.hasChild == '2'){
                                  return{
                                       children:[],
                                       loading:false,
                                       title:item.name,
                                       value: item.sysModuleId,
                                       ...item
                                     }
                                    
                                }else{
                                     return{
                                       title:item.name,
                                       value:item.sysModuleId,
                                       ...item
                                     }
                                }  
                            })
                            item.loading = false
                            item.children = arr
                            callback(item.children)
                        }else{
                            callback([])
                        }
                    }
                })
        },
        // 接口部分
        // 获取树节点结构
        getTree(parentId,isCall){
            this.$get('/auth/api/auth/pc/systemModule/cascade',{
                parentId,
                systemId:'13' || this.$route.query.systemId
            }).then(res=>{
                if(res.code == 200){
                    if(res.dataList && res.dataList.length != 0){
                        res.dataList.map(item =>{
                            item.title = item.name
                            item.value = item.sysModuleId
                            if(item.hasChild == '2'){
                                item.children = []
                                item.loading = false
                            }
                        })
                    }
                    this.treeData = res.dataList
                }
            })
        }
    },
    created(){
        this.getTree('0')
    }
}
</script>

<style lang="less" scoped>
.tree{
    height: 500px;
    overflow: scroll;
}
</style>